import useSWR from "swr";
import { DEV_MODE } from "../../env";
import { HydrationData } from "../../../../libs/types/hydration";
import { Icon } from "../../ui/atoms/icon";
import Mandala from "../../ui/molecules/mandala";

import "./index.scss";
const contributorGraphic = `${
  process.env.PUBLIC_URL || ""
}/assets/mdn_contributor.png`;

export function ContributorSpotlight(props: HydrationData<any>) {
  const fallbackData = props.hyData ? props : undefined;

  const { data: { hyData } = {} } = useSWR<any>(
    "./index.json",
    async (url) => {
      const response = await fetch(url);
      if (!response.ok) {
        const text = await response.text();
        throw new Error(`${response.status} on ${url}: ${text}`);
      }
      return await response.json();
    },
    {
      fallbackData,
      revalidateOnFocus: DEV_MODE,
      revalidateOnMount: !fallbackData,
    }
  );

  return hyData && hyData?.featuredContributor ? (
    <div className="contributor-spotlight dark">
      <div className="wrapper">
        <div className="text-col">
          <h3>Contributor Spotlight</h3>
          <a
            className="contributor-name"
            href={hyData?.featuredContributor?.url}
          >
            {hyData?.featuredContributor?.contributorName}
          </a>
          <blockquote>
            <Icon name="quote"></Icon>
            {hyData?.featuredContributor?.quote}
          </blockquote>
          <a href="/en-US/community" className="spotlight-cta">
            Get involved →
          </a>
        </div>
        <figure className="contributor-graphic">
          <img
            width="523"
            height="323"
            src={contributorGraphic}
            alt="Tiled Mozilla Logo"
          />
        </figure>
      </div>
      <Mandala />
    </div>
  ) : null;
}
